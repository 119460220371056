.fachanwaltschaft {
  flex-direction: column;
  position: relative;
}

.fachanwaltschaft .frame-36 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  position: relative;
}

.fachanwaltschaft .frame-37 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .apfelbaumstr-17 {
  align-self: stretch;
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.fachanwaltschaft .frame-38 {
  align-items: center;
  background-color: #f4bb3633;
  border: 1px dashed;
  border-color: var(--bewertung);
  border-radius: 5px;
  display: inline-flex;
  gap: 4px;
  left: 217px;
  padding: 2px 10px;
  position: absolute;
  top: 22px;
}

.fachanwaltschaft .rechtecheck-PRO {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: right;
  white-space: nowrap;
  width: fit-content;
}

.fachanwaltschaft .info {
  height: 14px !important;
  position: relative !important;
  width: 14px !important;
}

.fachanwaltschaft .apfelbaumstr-18 {
  color: var(--rc-body-dark);
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.fachanwaltschaft .icons-24-general-edit-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.fachanwaltschaft .frame-39 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .apfelbaumstr-19 {
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fachanwaltschaft .frame-40 {
  align-items: center;
  background-color: #f4bb3633;
  border: 1px dashed;
  border-color: var(--bewertung);
  border-radius: 5px;
  display: inline-flex;
  gap: 4px;
  justify-content: flex-end;
  left: 517px;
  padding: 2px 10px;
  position: absolute;
  top: 22px;
}

.fachanwaltschaft .headline-instance {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.fachanwaltschaft .frame-41 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .frame-42 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.fachanwaltschaft .vector-3 {
  height: 16.06px !important;
  left: unset !important;
  margin-left: -1.23px !important;
  position: relative !important;
  top: unset !important;
  width: 20.71px !important;
}

.fachanwaltschaft .text-wrapper-9 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fachanwaltschaft .frame-43 {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--whiteeeeee);
  border: 1px solid;
  border-color: var(--bewertung);
  border-radius: 8px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 20px;
  padding: 24px 16px;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .frame-44 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .apfelbaumstr-20 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.fachanwaltschaft .frame-45 {
  align-items: center;
  background-color: #f4bb3633;
  border: 1px dashed;
  border-color: var(--bewertung);
  border-radius: 5px;
  display: inline-flex;
  gap: 4px;
  padding: 2px 10px;
  position: absolute;
  top: 20px;
}

.fachanwaltschaft .text-wrapper-10 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.fachanwaltschaft .subtext {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 3px;
  position: relative;
  width: 100%;
}

.fachanwaltschaft .text-wrapper-11 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 18px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.fachanwaltschaft .text-wrapper-12 {
  align-self: stretch;
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 16px;
  position: relative;
  text-align: center;
}

.fachanwaltschaft .class {
  align-items: center !important;
  background-color: var(--lc-trkis) !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
}

.fachanwaltschaft .class-2 {
  align-items: center !important;
  align-self: stretch !important;
  background-color: var(--lc-trkis) !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  width: 100% !important;
}

.fachanwaltschaft .CTA-instance {
  color: var(--whiteeeeee) !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.fachanwaltschaft.device-10-desktop {
  background-color: var(--whiteeeeee);
  border-radius: 8px;
  display: flex;
  width: 660px;
}

.fachanwaltschaft.editierbar {
  align-items: flex-start;
  background-color: var(--whiteeeeee);
  border-radius: 8px;
  display: flex;
  gap: 16px;
  padding: 24px;
}

.fachanwaltschaft.blocked {
  align-items: flex-start;
}

.fachanwaltschaft.teaser {
  align-items: center;
  background-color: var(--whiteeeeee);
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 8px;
  display: flex;
  gap: 12px;
  padding: 24px 16px;
}

.fachanwaltschaft.device-10-desktop.blocked {
  gap: 16px;
  padding: 24px;
}

.fachanwaltschaft.blocked.device-10-mobile {
  display: inline-flex;
}

.fachanwaltschaft.editierbar.device-10-mobile {
  width: 351px;
}

.fachanwaltschaft.teaser.device-10-mobile {
  width: 351px;
}

.fachanwaltschaft.device-10-desktop .frame-36 {
  flex-direction: column;
}

.fachanwaltschaft.editierbar .frame-36 {
  align-self: stretch;
  width: 100%;
}

.fachanwaltschaft.blocked .frame-36 {
  background-color: var(--whiteeeeee);
  border-radius: 8px;
  flex-direction: column;
  gap: 20px;
  padding: 24px;
  width: 351px;
}

.fachanwaltschaft.teaser .frame-36 {
  width: 351px;
}

.fachanwaltschaft.device-10-desktop.editierbar .frame-36 {
  gap: 8px;
}

.fachanwaltschaft.editierbar.device-10-mobile .frame-36 {
  gap: 16px;
}

.fachanwaltschaft.device-10-desktop.teaser .frame-36 {
  gap: 8px;
}

.fachanwaltschaft.teaser.device-10-mobile .frame-36 {
  gap: 16px;
}

.fachanwaltschaft.device-10-mobile .frame-41 {
  align-items: center;
  flex-direction: column;
  gap: 12px;
}

.fachanwaltschaft.device-10-desktop .frame-41 {
  align-items: flex-start;
  gap: 24px;
  justify-content: center;
}

.fachanwaltschaft.device-10-mobile .frame-45 {
  left: 209px;
}

.fachanwaltschaft.device-10-desktop .frame-45 {
  left: 510px;
}
