.kontaktdaten-anwalt {
  align-items: flex-start;
  background-color: var(--whiteeeeee);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  position: relative;
}

.kontaktdaten-anwalt .div {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .apfelbaumstr {
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt .close {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.kontaktdaten-anwalt .frame-2 {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.kontaktdaten-anwalt .frame-3 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .apfelbaumstr-2 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-3 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  position: relative;
}

.kontaktdaten-anwalt .frame-4 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-4 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt .p {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0.33px;
  line-height: 22px;
  position: relative;
}

.kontaktdaten-anwalt .icons-24-general-edit {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.kontaktdaten-anwalt .frame-5 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .frame-6 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-5 {
  font-weight: 400;
  margin-top: -1px;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-6 {
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-7 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt .frame-7 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.kontaktdaten-anwalt .frame-8 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .img {
  height: 10px;
  position: relative;
  width: 20px;
}

.kontaktdaten-anwalt .text-wrapper-2 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0.33px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 250px;
}

.kontaktdaten-anwalt .mask {
  height: 13.33px;
  position: relative;
  width: 16.67px;
}

.kontaktdaten-anwalt .mask-2 {
  height: 15px;
  position: relative;
  width: 15px;
}

.kontaktdaten-anwalt .text-wrapper-3 {
  color: var(--rc-blue-dark);
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0.33px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.kontaktdaten-anwalt .text-wrapper-4 {
  color: var(--rc-body-light);
  font-family: "Oxygen", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.kontaktdaten-anwalt .apfelbaumstr-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
  width: 303px;
}

.kontaktdaten-anwalt .frame-9 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.kontaktdaten-anwalt .frame-10 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
}

.kontaktdaten-anwalt .div-2 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-8 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-right: -16px;
  margin-top: -1px;
  position: relative;
  width: 303px;
}

.kontaktdaten-anwalt .frame-11 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .div-wrapper {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
  width: 83px;
}

.kontaktdaten-anwalt .frame-12 {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
}

.kontaktdaten-anwalt .frame-13 {
  align-items: flex-start;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .text-wrapper-5 {
  color: var(--rc-body-light);
  font-family: "Oxygen", Helvetica;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: 614px;
}

.kontaktdaten-anwalt .frame-14 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  position: relative;
  width: 614px;
}

.kontaktdaten-anwalt .apfelbaumstr-9 {
  color: var(--rc-blue-dark);
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
}

.kontaktdaten-anwalt .frame-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.kontaktdaten-anwalt .frame-16 {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: 303px;
}

.kontaktdaten-anwalt .frame-17 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
  position: relative;
}

.kontaktdaten-anwalt .frame-18 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  gap: 7px;
  position: relative;
}

.kontaktdaten-anwalt .apfelbaumstr-10 {
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt .ellipse {
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 7.5px;
  height: 15px;
  position: relative;
  width: 15px;
}

.kontaktdaten-anwalt .frame-19 {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  gap: 10px;
  height: 34px;
  padding: 8px;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .apfelbaumstr-11 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt .frame-20 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  position: relative;
}

.kontaktdaten-anwalt .frame-21 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 303px;
}

.kontaktdaten-anwalt .frame-22 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 7px;
  margin-right: -1px;
  position: relative;
}

.kontaktdaten-anwalt .frame-23 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.kontaktdaten-anwalt .apfelbaumstr-12 {
  color: var(--lc-trkis);
  font-family: "Ubuntu", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.kontaktdaten-anwalt.default {
  gap: 12px;
}

.kontaktdaten-anwalt.device-3-mobile {
  width: 351px;
}

.kontaktdaten-anwalt.editiermodus {
  gap: 16px;
}

.kontaktdaten-anwalt.device-3-desktop {
  width: 660px;
}

.kontaktdaten-anwalt.default .div {
  align-items: flex-start;
}

.kontaktdaten-anwalt.editiermodus .div {
  align-items: flex-end;
}

.kontaktdaten-anwalt.device-3-mobile .frame-2 {
  width: 244px;
}

.kontaktdaten-anwalt.device-3-desktop .frame-2 {
  flex: 1;
  flex-grow: 1;
}

.kontaktdaten-anwalt.device-3-mobile .frame-4 {
  align-self: stretch;
  display: flex;
  width: 100%;
}

.kontaktdaten-anwalt.device-3-desktop .frame-4 {
  display: inline-flex;
}

.kontaktdaten-anwalt.default .frame-5 {
  flex-direction: column;
  gap: 16px;
}

.kontaktdaten-anwalt.device-3-mobile .frame-5 {
  flex-direction: column;
}

.kontaktdaten-anwalt.editiermodus .frame-5 {
  gap: 8px;
}

.kontaktdaten-anwalt.default .frame-6 {
  align-self: stretch;
  display: flex;
  gap: 4px;
  width: 100%;
}

.kontaktdaten-anwalt.editiermodus .frame-6 {
  display: inline-flex;
}

.kontaktdaten-anwalt.default .apfelbaumstr-5 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  height: 22px;
  letter-spacing: 0.33px;
  line-height: 22px;
  white-space: nowrap;
}

.kontaktdaten-anwalt.editiermodus .apfelbaumstr-5 {
  color: var(--rc-body-light);
  font-family: "Oxygen", Helvetica;
  font-size: 10px;
  letter-spacing: 0;
  line-height: normal;
  width: fit-content;
}

.kontaktdaten-anwalt.default .apfelbaumstr-6 {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  height: 22px;
  letter-spacing: 0.33px;
  line-height: 22px;
  white-space: nowrap;
}

.kontaktdaten-anwalt.editiermodus .apfelbaumstr-6 {
  align-items: flex-start;
  border: 1px solid;
  border-color: var(--rc-body-light);
  border-radius: 3px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  padding: 8px;
  width: 303px;
}

.kontaktdaten-anwalt.default .frame-7 {
  align-self: stretch;
  display: flex;
  gap: 4px;
  width: 100%;
}

.kontaktdaten-anwalt.editiermodus .frame-7 {
  display: inline-flex;
}

.kontaktdaten-anwalt.device-3-desktop.editiermodus .frame-7 {
  margin-right: -2px;
}

.kontaktdaten-anwalt.device-3-mobile .frame-9 {
  display: inline-flex;
}

.kontaktdaten-anwalt.device-3-desktop .frame-9 {
  display: flex;
  margin-right: -2px;
  width: 614px;
}

.kontaktdaten-anwalt.device-3-mobile .frame-17 {
  display: inline-flex;
}

.kontaktdaten-anwalt.device-3-desktop .frame-17 {
  align-self: stretch;
  display: flex;
  width: 100%;
}

.kontaktdaten-anwalt.device-3-mobile .frame-18 {
  flex-direction: column;
  width: 303px;
}

.kontaktdaten-anwalt.device-3-desktop .frame-18 {
  align-self: stretch;
  width: 100%;
}
