.m-eintrag {
  background-color: var(--rc-grey-light);
  border: 1px none;
  height: 3074px;
  overflow: hidden;
  position: relative;
  width: 375px;
}

.m-eintrag .text-wrapper-14 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 21px;
  font-weight: 400;
  height: 24px;
  left: 260px;
  letter-spacing: 0.63px;
  line-height: normal;
  position: absolute;
  text-align: center;
  top: -86px;
  white-space: nowrap;
}

.m-eintrag .frame-48 {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 40px;
  left: 0;
  padding: 16px 0px 0px;
  position: absolute;
  top: 72px;
  width: 375px;
}

.m-eintrag .frame-49 {
  align-items: center;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 375px;
}

.m-eintrag .design-component-instance-node {
  flex: 0 0 auto !important;
}

.m-eintrag .frame-50 {
  align-items: flex-start;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 351px;
}

.m-eintrag .design-component-instance-node-2 {
  align-self: stretch !important;
  flex: 0 0 auto !important;
  width: 100% !important;
}

.m-eintrag .frame-51 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  padding: 0px 12px;
  position: relative;
  width: 100%;
}

.m-eintrag .title-wrapper {
  align-items: center;
  align-self: stretch;
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  gap: 7px;
  justify-content: center;
  padding: 12px 24px;
  position: relative;
  width: 100%;
}

.m-eintrag .title-3 {
  color: var(--rc-blue-dark);
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.m-eintrag .frame-52 {
  align-items: center;
  align-self: stretch;
  background-color: var(--rc-blue-dark);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  position: relative;
  width: 100%;
}

.m-eintrag .frame-53 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 12px;
  margin-left: -12px;
  margin-right: -12px;
  position: relative;
}

.m-eintrag .text-wrapper-15 {
  color: var(--whiteeeeee);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  width: 327px;
}

.m-eintrag .CTA-2 {
  align-items: center !important;
  background-color: var(--lc-trkis) !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  width: 351px !important;
}

.m-eintrag .CTA-3 {
  color: var(--whiteeeeee) !important;
  flex: 1 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  white-space: unset !important;
  width: unset !important;
}

.m-eintrag .rechtecheck-pro-mehr {
  align-self: stretch;
  color: var(--whiteeeeee);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.7px;
  position: relative;
}

.m-eintrag .text-wrapper-16 {
  color: #ffffff;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20.7px;
}

.m-eintrag .text-wrapper-17 {
  font-size: 20px;
  font-weight: 500;
  line-height: 25.9px;
}

.m-eintrag .frame-54 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 16px;
  position: relative;
  width: 100%;
}

.m-eintrag .frame-55 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
  width: 100%;
}

.m-eintrag .vector-instance {
  height: 12px !important;
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 18px !important;
}

.m-eintrag .text-wrapper-18 {
  color: var(--whiteeeeee);
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  margin-top: -1px;
  position: relative;
}

.m-eintrag .CTA-4 {
  align-items: center !important;
  background-color: var(--lc-trkis) !important;
  display: flex !important;
  flex: 0 0 auto !important;
  justify-content: center !important;
  margin-left: -12px !important;
  margin-right: -12px !important;
  width: 351px !important;
}

.m-eintrag .CTA-5 {
  color: var(--whiteeeeee) !important;
  font-size: 16px !important;
  line-height: 26px !important;
  margin-left: -11.5px !important;
  margin-right: -11.5px !important;
}

.m-eintrag .image {
  height: 33px;
  left: 311px;
  object-fit: cover;
  position: absolute;
  top: 163px;
  width: 45px;
}

.m-eintrag .group {
  height: 90px;
  position: relative;
  width: 356.79px;
}

.m-eintrag .overlap-group {
  height: 90px;
  position: relative;
  width: 357px;
}

.m-eintrag .frame-56 {
  align-items: center;
  background-color: #bce8e0;
  border-radius: 6px;
  display: flex;
  gap: 7px;
  height: 72px;
  left: 0;
  padding: 11px 16px;
  position: absolute;
  top: 0;
  width: 330px;
}

.m-eintrag .frame-57 {
  align-items: flex-end;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 7px;
  position: relative;
}

.m-eintrag .title-4 {
  color: var(--rc-body-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

.m-eintrag .title-5 {
  color: var(--rc-body-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  width: fit-content;
}

.m-eintrag .promo-bot-instance {
  height: 72px !important;
  justify-content: center !important;
  left: 285px !important;
  position: absolute !important;
  top: 18px !important;
  width: 72px !important;
}

.m-eintrag .faqs-mobile-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 375px !important;
}

.m-eintrag .placeholder {
  height: 179px;
  margin-right: -2px;
  position: relative;
  width: 377px;
}

.m-eintrag .overlap {
  background-image: url(../../../static/img/image-60.png);
  background-position: 50% 50%;
  background-size: cover;
  height: 179px;
  position: relative;
  width: 375px;
}

.m-eintrag .image-2 {
  background-color: #2c2c2cb2;
  height: 179px;
  left: 0;
  position: absolute;
  top: 0;
  width: 375px;
}

.m-eintrag .text-wrapper-19 {
  color: var(--bewertung);
  font-family: "Verdana-Bold", Helvetica;
  font-size: 14px;
  font-weight: 700;
  left: 142px;
  letter-spacing: 0.42px;
  line-height: 22px;
  position: absolute;
  top: 79px;
  white-space: nowrap;
}

.m-eintrag .frame-58 {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  left: 0;
  position: fixed;
  top: 0;
}

.m-eintrag .frame-59 {
  align-items: center;
  background-color: var(--whiteeeeee);
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  padding: 16px 12px;
  position: relative;
  width: 375px;
}

.m-eintrag .frame-60 {
  align-items: flex-end;
  border: 1px solid;
  border-color: var(--rc-body-light);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4px 10px 4px 0px;
  position: relative;
  width: 150px;
}

.m-eintrag .text-wrapper-20 {
  align-self: stretch;
  color: var(--rc-body-light);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.m-eintrag .text-wrapper-21 {
  color: var(--rc-body-light);
  font-family: "Inter", Helvetica;
  font-size: 8px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 14px;
  margin-top: -6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.m-eintrag .frame-61 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 16px;
  position: relative;
}

.m-eintrag .frame-62 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: flex-end;
  position: relative;
}

.m-eintrag .frame-63 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 4px;
  position: relative;
}

.m-eintrag .text-wrapper-22 {
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.48px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.m-eintrag .image-3 {
  height: 31px;
  object-fit: cover;
  position: relative;
  width: 41px;
}

.m-eintrag .line-2 {
  height: 1px;
  margin-bottom: -1px;
  object-fit: cover;
  position: relative;
  width: 375px;
}
