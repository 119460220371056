.schwerpunkte-default {
  align-items: flex-start;
  background-color: var(--rc-blue-dark);
  border-radius: 5px;
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  padding: 8px 12px;
  position: relative;
}

.schwerpunkte-default .sozialrecht {
  color: var(--whiteeeeee);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.29px;
  line-height: normal;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}
