.promo-bot {
  align-items: center;
  background-color: var(--lc-trkis);
  border-radius: 7px;
  box-shadow: 0px 4px 9px #00000033;
  gap: 8px;
  height: 66px;
  padding: 10px 12px;
  position: relative;
}

.promo-bot .frame-46 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.promo-bot .title {
  color: var(--rc-body-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.promo-bot .title-2 {
  color: var(--rc-body-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -6px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.promo-bot .chat-bubble {
  height: 39px !important;
  position: relative !important;
  width: 39px !important;
}

.promo-bot.property-1-default {
  display: flex;
  width: 66px;
}

.promo-bot.property-1-variant-3 {
  display: inline-flex;
}
