.headline {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
}

.headline .frame {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.headline .HL {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 25px;
  margin-top: -1px;
  position: relative;
  text-align: center;
}

.headline .SUB-HL {
  align-self: stretch;
  color: var(--rc-blue-dark);
  font-family: "Ubuntu", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 25px;
  position: relative;
  text-align: center;
}

.headline .body {
  align-self: stretch;
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
  text-align: center;
}

.headline .text-wrapper {
  color: var(--rc-blue-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.headline.mobile {
  gap: 4px;
  width: 351px;
}

.headline.desktop {
  gap: 8px;
  width: 660px;
}

.headline.desktop .frame {
  gap: 2px;
}

.headline.mobile .HL {
  font-size: 18px;
}

.headline.desktop .HL {
  font-size: 24px;
}

.headline.mobile .SUB-HL {
  font-size: 18px;
}

.headline.desktop .SUB-HL {
  font-size: 24px;
}

.headline.mobile .body {
  font-size: 14px;
}

.headline.desktop .body {
  font-size: 16px;
}
