.rechtsgebiete {
  align-items: flex-start;
  background-color: var(--whiteeeeee);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
  position: relative;
  width: 351px;
}

.rechtsgebiete .frame-24 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.rechtsgebiete .frame-25 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.rechtsgebiete .apfelbaumstr-13 {
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.33px;
  line-height: 22px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .close-7 {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.rechtsgebiete .apfelbaumstr-14 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .icons-general-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.rechtsgebiete .frame-26 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  position: relative;
}

.rechtsgebiete .schwerpunkte-default-s {
  flex: 0 0 auto !important;
}

.rechtsgebiete .frame-27 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 100%;
}

.rechtsgebiete .frame-28 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.rechtsgebiete .frame-wrapper {
  align-items: flex-start;
  background-color: var(--rc-blue-dark);
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  height: 32px;
  padding: 8px 12px;
  position: relative;
}

.rechtsgebiete .frame-29 {
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  justify-content: center;
  position: relative;
}

.rechtsgebiete .close-2 {
  height: 15px !important;
  position: relative !important;
  width: 15px !important;
}

.rechtsgebiete .text-wrapper-6 {
  color: var(--whiteeeeee);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.29px;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .frame-30 {
  align-items: flex-start;
  display: inline-flex;
  gap: 8px;
  position: relative;
}

.rechtsgebiete .schwerpunkte-default-2 {
  align-items: flex-start;
  background-color: var(--rc-blue-dark);
  border-radius: 5px;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  padding: 8px 12px;
  position: relative;
}

.rechtsgebiete .frame-31 {
  align-items: flex-start;
  flex: 0 0 auto;
  flex-direction: column;
  position: relative;
}

.rechtsgebiete .apfelbaumstr-15 {
  position: relative;
}

.rechtsgebiete .frame-32 {
  align-items: center;
  background-color: var(--whiteeeeee);
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 5px;
  display: flex;
  flex: 0 0 auto;
  gap: 10px;
  overflow: hidden;
  padding: 8px 12px;
  position: relative;
  width: 303px;
}

.rechtsgebiete .frame-33 {
  align-items: center;
  display: flex;
  flex: 1;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.rechtsgebiete .text-wrapper-7 {
  color: var(--rc-blue-dark);
  font-family: "Oxygen", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .close-1 {
  height: 20px !important;
  position: relative !important;
  width: 20px !important;
}

.rechtsgebiete .line {
  height: 26px;
  left: 32px;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 1px;
}

.rechtsgebiete .frame-34 {
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  position: relative;
  width: 100%;
}

.rechtsgebiete .text-wrapper-8 {
  color: var(--rc-body-light);
  font-family: "Oxygen", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 26px;
  margin-top: -1px;
  position: relative;
  width: 172px;
}

.rechtsgebiete .search {
  background-image: url(../../../static/img/shape.svg);
  background-size: 100% 100%;
  height: 19.92px;
  position: relative;
  width: 19.92px;
}

.rechtsgebiete .schwerpunkte-default-instance {
  color: var(--rc-blue-dark) !important;
  margin-top: -0.5px !important;
}

.rechtsgebiete .schwerpunkte-default-s-instance {
  background-color: var(--whiteeeeee) !important;
  border: 1px solid !important;
  border-color: var(--rc-blue-dark) !important;
  flex: 0 0 auto !important;
  margin-left: -1px !important;
  margin-top: -1px !important;
}

.rechtsgebiete .instance-node {
  background-color: var(--whiteeeeee) !important;
  border: 1px solid !important;
  border-color: var(--rc-blue-dark) !important;
  flex: 0 0 auto !important;
  margin-bottom: -1px !important;
  margin-left: -1px !important;
}

.rechtsgebiete .frame-35 {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: flex-end;
  position: relative;
  width: 100%;
}

.rechtsgebiete .apfelbaumstr-16 {
  font-family: "Ubuntu", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .status-5-default {
  gap: 8px;
}

.rechtsgebiete .status-5-one-editiermodus {
  gap: 16px;
}

.rechtsgebiete .status-5-two-search-active {
  gap: 16px;
}

.rechtsgebiete .status-5-three-changed {
  gap: 16px;
}

.rechtsgebiete .frame-24.status-5-default .frame-25 {
  align-items: flex-start;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .frame-25 {
  align-items: center;
}

.rechtsgebiete .frame-24.status-5-two-search-active .frame-25 {
  align-items: center;
}

.rechtsgebiete .frame-24.status-5-three-changed .frame-25 {
  align-items: center;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .frame-30 {
  flex: 0 0 auto;
}

.rechtsgebiete .frame-24.status-5-two-search-active .frame-30 {
  height: 32px;
}

.rechtsgebiete .frame-24.status-5-three-changed .frame-30 {
  flex: 0 0 auto;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .schwerpunkte-default-2 {
  height: 32px;
}

.rechtsgebiete .frame-24.status-5-three-changed .schwerpunkte-default-2 {
  height: 32px;
}

.rechtsgebiete .frame-24.status-5-default .frame-31 {
  display: flex;
  gap: 4px;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .frame-31 {
  align-self: stretch;
  display: flex;
  gap: 4px;
  width: 100%;
}

.rechtsgebiete .frame-24.status-5-two-search-active .frame-31 {
  display: inline-flex;
  gap: 16px;
}

.rechtsgebiete .frame-24.status-5-three-changed .frame-31 {
  align-self: stretch;
  display: flex;
  gap: 4px;
  width: 100%;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .apfelbaumstr-15 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .frame-24.status-5-two-search-active .apfelbaumstr-15 {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 4px;
}

.rechtsgebiete .frame-24.status-5-three-changed .apfelbaumstr-15 {
  color: var(--rc-body-light);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  white-space: nowrap;
  width: fit-content;
}

.rechtsgebiete .frame-24.status-5-default .frame-34 {
  align-items: center;
  gap: 10px;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .frame-34 {
  align-items: center;
  background-color: var(--whiteeeeee);
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 5px;
  gap: 10px;
  overflow: hidden;
  padding: 8px 12px;
}

.rechtsgebiete .frame-24.status-5-two-search-active .frame-34 {
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
}

.rechtsgebiete .frame-24.status-5-three-changed .frame-34 {
  align-items: center;
  background-color: var(--whiteeeeee);
  border: 1px solid;
  border-color: var(--rc-blue-dark);
  border-radius: 5px;
  gap: 10px;
  overflow: hidden;
  padding: 8px 12px;
}

.rechtsgebiete .frame-24.status-5-default .apfelbaumstr-16 {
  color: #49d6b780;
}

.rechtsgebiete .frame-24.status-5-one-editiermodus .apfelbaumstr-16 {
  color: #49d6b780;
}

.rechtsgebiete .frame-24.status-5-two-search-active .apfelbaumstr-16 {
  color: #49d6b780;
}

.rechtsgebiete .frame-24.status-5-three-changed .apfelbaumstr-16 {
  color: var(--lc-trkis);
}
