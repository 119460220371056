.faqs-mobile {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 351px;
}

.faqs-mobile .element-expanded {
  align-items: flex-start;
  align-self: stretch;
  background-color: var(--whiteeeeee);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 8px;
  padding: 16px 22px;
  position: relative;
  width: 100%;
}

.faqs-mobile .frame-47 {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.faqs-mobile .is-this-thing-on {
  color: #4f5c68;
  flex: 1;
  font-family: "Ubuntu", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}

.faqs-mobile .icon {
  left: unset !important;
  position: relative !important;
  top: unset !important;
  width: 19.03px !important;
}

.faqs-mobile .answer {
  align-self: stretch;
  color: var(--rc-body-dark);
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
}

.faqs-mobile .link-instance {
  flex: 0 0 auto !important;
}

.faqs-mobile .is-this-thing-on-2 {
  color: var(--rc-blue-dark);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
}

.faqs-mobile .atoms-icons-system-instance {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}

.faqs-mobile .answer-2 {
  align-self: stretch;
  color: transparent;
  font-family: "Ubuntu", Helvetica;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 21px;
  position: relative;
}

.faqs-mobile .span {
  color: #535353;
}

.faqs-mobile .text-wrapper-13 {
  color: #49d6b7;
}
