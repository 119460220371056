.CTA {
  align-items: flex-start;
  background-color: #ff7f27;
  border-radius: 5px;
  display: inline-flex;
  gap: 10px;
  overflow: hidden;
  padding: 15px 40px;
  position: relative;
}

.CTA .jetzt-tage {
  color: #ffffff;
  font-family: "Ubuntu", Helvetica;
  font-size: 21px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}
