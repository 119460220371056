.link {
  align-items: flex-start;
  display: inline-flex;
  gap: 4px;
  position: relative;
}

.link .chevron-left {
  height: 24px !important;
  position: relative !important;
  width: 24px !important;
}

.link .div-3 {
  color: var(--lc-trkis);
  font-family: "Ubuntu", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0.33px;
  line-height: 22px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.link .atoms-icons-system-chevron-down {
  left: unset !important;
  position: relative !important;
  top: unset !important;
}
